$slide-title-font: 'alegreya_sans_sc', sans-serif;
$slide-title-font-weight: 900;

.Wallop-item h1,
.Wallop-item h2,
.Wallop-item h3,
.Wallop-item h4 {
    font-family: $slide-title-font;
    font-weight: $slide-title-font-weight;
    color: #1189D4;
    letter-spacing: 0.05em;
}

.Wallop-pagination {
    position: absolute;
    bottom: -0.25em;
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0)
}

.Wallop-dot {
    text-indent: -9999px;
    border-style: solid;
    border-width: 0.08em;
    border-radius: 0%;
    border-color: #000;
    width: 0.7em;
    height: 0.7em;
    padding: 0;
    margin: 0.25em;
    background-color: #bbb;
    -webkit-appearance: none;

    &:focus {
        outline: none;
    }
}

.Wallop-dot--current {
    background-color: #fff;
}

.slide-container {
    position: relative;
}

.slide-height-xsmall {
    height: 8em;
}

.slide-height-small {
    height: 9em;

    @media only screen and (min-width: 46em) {
        height: 10em;
    }

    @media only screen and (min-width: 61em) {
        height: 11em;
    }
}

.slide-height-medium {
    height: 11em;

    @media only screen and (min-width: 46em) {
        height: 13em;
    }

    @media only screen and (min-width: 61em) {
        height: 15em;
    }
}

.slide-height-large {
    height: 14em;

    @media only screen and (min-width: 46em) {
        height: 17em;
    }

    @media only screen and (min-width: 61em) {
        height: 20em;
    }
}

.slide-height-xlarge {
    height: 16em;

    @media only screen and (min-width: 46em) {
        height: 22em;
    }

    @media only screen and (min-width: 61em) {
        height: 26em;
    }
}


.slide-text {
    position: absolute;
    max-width: 90%;
    padding: 0.6em;

    color: #fff;
    background: rgb(0, 0, 0); // fallback color
    background: rgba(60, 60, 60, 0.55);

    font-family: 'alegreya_sans', sans-serif;
    font-weight: 600;

    strong {
        font-family: 'alegreya_sans_sc', sans-serif;
        font-style: normal;
        font-weight: 800;
    }

    h1,
    h2,
    h3,
    h4,
    a {
        font-family: 'alegreya_sans', sans-serif;
        font-weight: 800;
        letter-spacing: 0.05em;
        color: #fff;
    }

    p,
    ul {
        font-size: 1.1em;
        letter-spacing: 0.02em;
    }

    ul,
    ol {
        padding-left: 1em;
        margin-bottom: 0.5em;
        margin-top: 0.5em;
    }

    ul {
        list-style-type: square;
    }
}

.slide-text-top {
    top: 1.5em;
}

.slide-text-bottom {
    bottom: 2.0em;
}

.slide-text-left {
    left: 1.5em;
}

.slide-text-right {
    right: 1.5em;
}

.slide-text-middle {
    display: inline-block;
    position: absolute;
    /* remove from document flow */
    left: 50%;
    /* center horizontally */
    top: 50%;
    /* center vertically */
    transform: translate(-50%, -50%)
        /* tweak for precise centering; details:
                                       http://stackoverflow.com/q/36817249 */
}